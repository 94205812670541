import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import Divider from "@material-ui/core/Divider";

import { selectClaims } from "../claims/claimsSlice";
import { selectCustomisation } from "../customisation/customisationSlice";
import MemberOrders from "../orders/MemberOrders";
import { selectOrders } from "../orders/ordersSlice";
import { selectUserPoints } from "../userPoints/userPointsSlice";
import MemberDetails from "./MemberDetails";
import MemberPoints from "./MemberPoints";
import { fetchUserByID, selectUsers, fetchMyself } from "./usersSlice";

const useStyles = makeStyles((theme) => ({
  tabBar: {
    // backgroundColor: ' rgba(0, 0, 0, 0.08)',
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: 0,
    height: "100%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 3, 2),
  },
  linkText: {
    fontSize: 16,
    display: "flex",
    alignItems: "center",
  },
  headerContainer: {
    margin: theme.spacing(2),
  },
  subHeader: { margin: theme.spacing(2), marginBottom: 0 },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  tabContent: {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: 1,
  },
  root: {
    display: "flex",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserContainer(props) {
  // const {userAccount, error, loading} = props;
  const classes = useStyles();
  const { error, loading, users, user, signedIn, userAccount } =
    useSelector(selectUsers);
  const { customisation } = useSelector(selectCustomisation);

  const { claimsList } = useSelector(selectClaims);
  const { ordersList } = useSelector(selectOrders);
  const { pointsHistory } = useSelector(selectUserPoints);
  const location = useLocation();
  let jwtToken = localStorage.getItem("clientToken");

  const dispatch = useDispatch();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!user.id) {
      let userID = location.pathname.split("/")[3];
      dispatch(
        fetchUserByID({
          id: userID,
          token: jwtToken,
        })
      );
    }
  }, []);
  
  useEffect(() => {
    dispatch(fetchMyself({
      token: jwtToken,
    }))
  }, [])

  return (
    <Container className={classes.paper} component="main" maxWidth="lg">
      {/* // <Container className={classes.paper} fixed> */}
      {loading === "loaded" && user && (
        <Card style={{ height: "100%" }} elevation={4}>
          <AppBar className={classes.tabBar} position="static">
            <Tabs
              classes={{
                indicator: classes.indicator,
              }}
              value={value}
              onChange={handleChange}
              aria-label="Member Account Tabs"
            >
              <Tab label="Member Details" {...a11yProps(0)} />
              <Tab label="Points History" {...a11yProps(1)} />
              {/* <Tab label="Claims History" {...a11yProps(2)} /> */}
              <Tab label="Orders History" {...a11yProps(2)} />
            </Tabs>
          </AppBar>

          <Grid
            container
            role="tabpanel"
            hidden={value !== 0}
            id={`simple-tabpanel-${0}`}
            aria-labelledby={`simple-tab-${0}`}
          >
            {value === 0 && (
              <div className={classes.tabContent}>
                <MemberDetails userAccount={userAccount} user={user} />

                <Divider />
              </div>
            )}
          </Grid>
          <Grid
            container
            role="tabpanel"
            hidden={value !== 1}
            id={`simple-tabpanel-${1}`}
            aria-labelledby={`simple-tab-${1}`}
          >
            {value === 1 && (
              <div className={classes.tabContent}>
                <MemberPoints
                  pointsList={pointsHistory}
                  userAccount={userAccount}
                  user={user}
                />
              </div>
            )}
          </Grid>
          {/* <Grid
            container
            role="tabpanel"
            hidden={value !== 2}
            id={`simple-tabpanel-${2}`}
            aria-labelledby={`simple-tab-${2}`}
          >
            {value === 2 && (
              <div className={classes.tabContent}>
                <MemberClaims claimsList={claimsList} userAccount={userAccount} user={user} />
              </div>
            )}
          </Grid> */}
          <Grid
            container
            role="tabpanel"
            hidden={value !== 2}
            id={`simple-tabpanel-${2}`}
            aria-labelledby={`simple-tab-${2}`}
          >
            {value === 2 && (
              <div className={classes.tabContent}>
                <MemberOrders
                  ordersList={ordersList}
                  userAccount={userAccount}
                  user={user}
                />
              </div>
            )}
          </Grid>
        </Card>
      )}
    </Container>
  );
}
